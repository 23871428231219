import React from 'react';
import Slider from "react-slick";
import { useSpring, animated } from '@react-spring/web';
import { useGesture } from 'react-use-gesture';
import S1 from "../../assets/images/s1.png";
import S2 from "../../assets/images/s2.png";
import S3 from "../../assets/images/s3.png";
import S4 from "../../assets/images/s4.png";
import S5 from "../../assets/images/s5.png";
import S6 from "../../assets/images/s6.png";
import S7 from "../../assets/images/s7.png";
import S8 from "../../assets/images/s8.png";
import S9 from "../../assets/images/s9.png";
import S10 from "../../assets/images/s10.png";
import S11 from "../../assets/images/s11.png";
import S12 from "../../assets/images/s12.png";
import S13 from "../../assets/images/img10.jpg";

const products = [
  { id: 1, name: { en: "Manual operated Roller blinds", ar: "ستائر رول يدوي التشغيل" }, price: { en: "55 SR m²", ar: "55 ريال سعودي م²" }, image: S1 },
  { id: 2, name: { en: "Leather Accordion door", ar: "باب أكورديون جلد" }, price: { en: "350 SR m²", ar: "350 ريال سعودي م²" }, image: S2 },
  { id: 3, name: { en: "Roman shades", ar: "ستائر رومانية" }, price: { en: "80 SR m²", ar: "80 ريال سعودي م²" }, image: S3 },
  { id: 4, name: { en: "Hospital Curtains", ar: "ستائر المستشفى" }, price: { en: "220 SR Lm", ar: "220 ريال سعودي متر طولي" }, image: S4 },
  { id: 5, name: { en: "PVC strip curtains", ar: "ستائر شرائح PVC" }, price: { en: "160 SR m²", ar: "160 ريال سعودي م²" }, image: S5 },
  { id: 6, name: { en: "American Curtains", ar: "ستائر أمريكية" }, price: { en: "150 ~250 SR m²", ar: "150 ~250 ريال سعودي م²" }, image: S6 },
  { id: 7, name: { en: "Wooden Blinds 50mm", ar: "ستائر خشبية 50 مم" }, price: { en: "120 SR m²", ar: "120 ريال سعودي م²" }, image: S7 },
  { id: 8, name: { en: "PVC accordion doors Wooden texture", ar: "أبواب أكورديون PVC بنسيج خشبي" }, price: { en: "140 SR m²", ar: "140 ريال سعودي م²" }, image: S8 },
  { id: 9, name: { en: "Vertical Blinds 127mm", ar: "ستائر عمودية 127 مم" }, price: { en: "50 SR m²", ar: "50 ريال سعودي م²" }, image: S9 },
  { id: 10, name: { en: "Aluminum Venetian Blinds 50mm", ar: "ستائر فينيسية ألمنيوم 50 مم" }, price: { en: "65 SR m²", ar: "65 ريال سعودي م²" }, image: S10 },
  { id: 11, name: { en: "Aluminum Venetian Blinds 25mm", ar: "ستائر فينيسية ألمنيوم 25 مم" }, price: { en: "50 SR m²", ar: "50 ريال سعودي م²" }, image: S11 },
  { id: 12, name: { en: "PVC accordion doors Plain colour", ar: "أبواب أكورديون PVC لون سادة" }, price: { en: "110 SR m²", ar: "110 ريال سعودي م²" }, image: S12 },
  { id: 13, name: { en: "Aluminum windows and door", ar: "نوافذ وأبواب ألمنيوم" }, price: { en: "110 SR m²", ar: "110 ريال سعودي م²" }, image: S13 },
];

const ProductCard = ({ product, language }) => {
  const [props, api] = useSpring(() => ({ scale: 1 }));

  const bind = useGesture({
    onHover: ({ hovering }) => api({ scale: hovering ? 1.1 : 1 }),
  });

  return (
    <animated.div
      {...bind()}
      className="bg-white shadow-md rounded-lg overflow-hidden m-2 h-[310px]"
      style={{ transform: props.scale.to(s => `scale(${s})`) }}
    >
      <img src={product.image} alt={product.name[language]} className="w-full h-48 object-cover mb-3" />
      <div className="py-4 px-1 text-center">
        <h2 className="text-lg font-semibold text-gray-800">{product.name[language]}</h2>
       
      </div>
    </animated.div>
  );
};

const ProductCarousel = ({ text, language }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto py-8" id="featured-products" data-aos="zoom-in">
      <h1 className="text-center font-[800] text-[45px] pb-7">{text.shopNow}</h1>
      <Slider {...settings}>
        {products.map((product) => (
          <ProductCard key={product.id} product={product} language={language} />
        ))}
      </Slider>
    </div>
  );
};

export default ProductCarousel;
