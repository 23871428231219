import React from 'react';
import AboutUS from "../../assets/images/about.PNG";

// Define the translations for both languages
const translations = {
    en: {
        title: "About Us",
        description: `Welcome to Karam AlAfaq, your premier destination for exquisite window curtain & Aluminum solutions. With a steadfast commitment to quality craftsmanship and innovative design, we specialize in manufacturing premium window curtains & Aluminum products that elevate the ambiance of any space. Established with a passion for enhancing interior aesthetics, our company prides itself on delivering unparalleled elegance and functionality in every product we produce. From timeless classics to contemporary marvels, our diverse range of products caters to various styles and preferences, ensuring that every customer finds the perfect fit for their home or business. Backed by years of industry expertise, we strive for excellence in every aspect of our operation, from material selection to customer service. At Karam AlAfaq, we envision transforming sites into focal points of beauty & sophistication.`,
    },
    ar: {
        title: "من نحن",
        description: `مرحبًا بكم في كرم الأفق، وجهتكم المميزة لحلول الستائر النوافذ والألومنيوم الرائعة. مع التزامنا الثابت بجودة الحرفية والتصميم المبتكر، نتخصص في تصنيع منتجات الستائر النوافذ والألومنيوم الفاخرة التي تعزز من أجواء أي مكان. تأسست شركتنا بشغف لتحسين الجماليات الداخلية، وتفخر بتقديم أناقة ووظائف لا مثيل لها في كل منتج نقوم بإنتاجه. من الكلاسيكيات الخالدة إلى العجائب المعاصرة، تلبي مجموعتنا المتنوعة من المنتجات أساليب وتفضيلات مختلفة، مما يضمن أن يجد كل عميل ما يناسب منزله أو عمله. مدعومة بخبرة سنوات في الصناعة، نسعى لتحقيق التميز في كل جانب من جوانب عملياتنا، من اختيار المواد إلى خدمة العملاء. في كرم الأفق، نتخيل تحويل المواقع إلى نقاط محورية للجمال والتطور.`,
    }
};

const Aboutus = ({ language }) => {
    const { title, description } = translations[language] || translations.en;

    return (
        <div className="container mx-auto py-8" id="about-us" data-aos="fade-up">
            <div>
                <h1 className="text-center font-[800] text-[45px] mb-8">{title}</h1>
            </div>
            <div className="flex justify-center">
                <img src={AboutUS} alt="About Us" className="w-full h-auto object-cover rounded-lg shadow-md" />
            </div>
            <div>
                <p className="text-center px-4 py-8 font-medium text-md md:text-xl leading-relaxed">
                    {description}
                </p>
            </div>
        </div>
    );
};

export default Aboutus;
