import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import 'leaflet/dist/leaflet.css'; // Ensure Leaflet styles are imported

// Define translations for the component
const translations = {
  en: {
    title: "Contact Us",
    officeAddress: "Our Office Address",
    generalEnquiries: "General Enquiries",
    callUs: "Call Us",
    ourTiming: "Our Timing"
  },
  ar: {
    title: "اتصل بنا",
    officeAddress: "عنوان مكتبنا",
    generalEnquiries: "الاستفسارات العامة",
    callUs: "اتصل بنا",
    ourTiming: "مواعيد العمل"
  }
};

// Component that accepts props for localization and content
const Footer = ({ 
  language = 'en', // Default to 'en' if no language is provided
  address, 
  email, 
  phoneNumbers, 
  timing, 
  mapPosition, 
  markerText 
}) => {
  // Default icon settings for Leaflet marker
  const customIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41]
  });

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  // Get translations based on the selected language
  const { 
    title, 
    officeAddress, 
    generalEnquiries, 
    callUs, 
    ourTiming 
  } = translations[language] || translations.en;

  return (
    <div className="bg-gray-100 py-16" id='contact-us'>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-center font-[800] text-[45px] mb-8" data-aos="fade-up">
          {title}
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="w-full h-80 lg:h-full" data-aos="fade-right">
            <MapContainer center={mapPosition} zoom={13} className="w-full h-full">
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={mapPosition} icon={customIcon}>
                <Popup>{markerText}</Popup>
              </Marker>
            </MapContainer>
          </div>
          <div className="flex flex-col justify-center space-y-6 px-6" data-aos="fade-left">
            <div>
              <h3 className="text-lg font-semibold">{officeAddress}</h3>
              <p>{address}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold">{generalEnquiries}</h3>
              <p>{email}</p>
              {/* <p>{additionalEmail}</p> */}
            </div>
            <div>
              <h3 className="text-lg font-semibold">{callUs}</h3>
              {phoneNumbers.map((number, index) => (
                <p key={index}>{number}</p>
              ))}
            </div>
            <div>
              <h3 className="text-lg font-semibold">{ourTiming}</h3>
              <p>{timing}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Default props for the component
Footer.defaultProps = {
  address: '7409 حضرموت، حي المناخ، RNCA3537، 3537, Riyadh 14316, Saudi Arabia',
  email: 'Sales@karamalafaq.net',
  phoneNumbers: ['+966503112485', '+966502989546'],
  timing: 'Mon - Sun : 10:00 AM - 07:00 PM',
  mapPosition: [24.774265, 46.738586],
  markerText: 'Emerald Blinds'
};

export default Footer;
