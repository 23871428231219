import './App.css';
import HeroPage from './pages/heroPage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "leaflet/dist/leaflet.css";
import 'aos/dist/aos.css';
import { Routes, Route } from "react-router-dom"
function App() {

  return (

    <div className="App overflow-hidden">
     
      <Routes>
        <Route path="/" element={<HeroPage />} />
      </Routes>
    </div>

  );
}

export default App;
