import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroImage from "../../assets/images/hero.png";
import Services from '../services';
import ProductCarousel from '../Products';
import Aboutus from '../aboutpage';
import OurPreviousProject from '../previousPage';
import TestimonialsCarousel from '../testimonialsPage';
import ContactUS from "../contactus/index"
import Logo from "../../assets/images/logo.png";
import Header from '../../components/header';
import Footer from '../contactus/index';

const HeroPage = () => {
    useEffect(() => {
        AOS.init({ duration: 1200 });
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [language, setLanguage] = useState('ar'); // Default language is Arabic

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    const text = {
        home: language === 'ar' ? 'الرئيسية' : 'Home',
        services: language === 'ar' ? 'الخدمات' : 'Services',
        shopNow: language === 'ar' ? 'تسوق الآن' : 'Shop now',
        aboutUs: language === 'ar' ? 'معلومات عنا' : 'About Us',
        ourProjects: language === 'ar' ? 'مشاريعنا' : 'Our Projects',
        testimonials: language === 'ar' ? 'الشهادات' : 'Testimonials',
        contactUs: language === 'ar' ? 'اتصل بنا' : 'Contact Us'
    };

    const contentText = {
        heroSection: {
            title1: language === 'ar' ? 'كرم الآفاق' : 'KARAM ALAFAQ',
            title2: language === 'ar' ? 'المقاولات' : 'Contracting',
            tagline2: language === 'ar' ? 'اختبر الراحة والأناقة مع ستائرنا الفاخرة' : 'Experience Comfort and Style with our Premium Curtains',
            button: language === 'ar' ? 'استفسر الآن' : 'Enquire Now'
        },
        servicesSection: {
            // Add translations for services section here if needed
        },
        productSection: {
            // Add translations for product section here if needed
        },
        aboutUsSection: {
            // Add translations for about us section here if needed
        },
        projectsSection: {
            // Add translations for projects section here if needed
        },
        testimonialsSection: {
            // Add translations for testimonials section here if needed
        },
        contactUsSection: {
            // Add translations for contact us section here if needed
        }
    };

    return (
        <>
            <header className="bg-[#e9e9e9] shadow-md py-2 relative">
                <div className="container mx-auto flex justify-between items-center py-1 px-4">
                    <img src={Logo} alt='' className='w-[180px] h-[80px]' />
                    <div className="block lg:hidden">
                        <button onClick={toggleMenu} className="text-gray-800 focus:outline-none">
                            <svg className="w-6 h-6" fill="none" stroke="black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                            </svg>
                        </button>
                    </div>
                    <nav className="hidden lg:flex lg:items-center">
                        <a href="#home" className="block mt-4 lg:inline-block lg:mt-0 text-gray-800 hover:text-gray-600 font-[500] mr-4 border-b-4 border-transparent hover:border-teal-800">{text.home}</a>
                        <a href="#services" className="block mt-4 lg:inline-block lg:mt-0 text-gray-800 hover:text-gray-600 font-[500] mr-4 border-b-4 border-transparent hover:border-teal-800">{text.services}</a>
                        <a href="#featured-products" className="block mt-4 lg:inline-block lg:mt-0 text-gray-800 hover:text-gray-600 font-[500] mr-4 border-b-4 border-transparent hover:border-teal-800">{text.shopNow}</a>
                        <a href="#about-us" className="block mt-4 lg:inline-block lg:mt-0 text-gray-800 hover:text-gray-600 font-[500] mr-4 border-b-4 border-transparent hover:border-teal-800">{text.aboutUs}</a>
                        <a href="#gallery" className="block mt-4 lg:inline-block lg:mt-0 text-gray-800 hover:text-gray-600 font-[500] mr-4 border-b-4 border-transparent hover:border-teal-800">{text.ourProjects}</a>
                        <a href="#testimonials" className="block mt-4 lg:inline-block lg:mt-0 text-gray-800 hover:text-gray-600 font-[500] mr-4 border-b-4 border-transparent hover:border-teal-800">{text.testimonials}</a>
                        <a href="#contact-us" className="block mt-4 lg:inline-block lg:mt-0 text-gray-800 hover:text-gray-600 font-[500] border-b-4 border-transparent hover:border-teal-800">{text.contactUs}</a>
                        <div className="">
                            <select onChange={handleLanguageChange} value={language} className="bg-white border border-gray-300 rounded-md  ms-4 p-2">
                                <option value="ar">العربية</option>
                                <option value="en">English</option>
                            </select>
                        </div>
                    </nav>
                </div>
                {isOpen && (
                    <div className="fixed inset-0 bg-[#e9e9e9] z-50 flex flex-col items-center justify-center">
                        <button onClick={toggleMenu} className="absolute top-4 right-4 text-gray-800 focus:outline-none">
                            <svg className="w-8 h-8" fill="none" stroke="black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                        <nav className="flex flex-col items-center">
                            <a href="#home" onClick={toggleMenu} className="block text-xl text-gray-800 hover:text-gray-600 font-[500] my-4">{text.home}</a>
                            <a href="#services" onClick={toggleMenu} className="block text-xl text-gray-800 hover:text-gray-600 font-[500] my-4">{text.services}</a>
                            <a href="#featured-products" onClick={toggleMenu} className="block text-xl text-gray-800 hover:text-gray-600 font-[500] my-4">{text.shopNow}</a>
                            <a href="#about-us" onClick={toggleMenu} className="block text-xl text-gray-800 hover:text-gray-600 font-[500] my-4">{text.aboutUs}</a>
                            <a href="#gallery" onClick={toggleMenu} className="block text-xl text-gray-800 hover:text-gray-600 font-[500] my-4">{text.ourProjects}</a>
                            <a href="#testimonials" onClick={toggleMenu} className="block text-xl text-gray-800 hover:text-gray-600 font-[500] my-4">{text.testimonials}</a>
                            <a href="#contact-us" onClick={toggleMenu} className="block text-xl text-gray-800 hover:text-gray-600 font-[500] my-4">{text.contactUs}</a>
                            <div className="">
                                <select onChange={handleLanguageChange} value={language} className="bg-white border border-gray-300 rounded-md p-2">
                                    <option value="ar">العربية</option>
                                    <option value="en">English</option>
                                </select>
                            </div>
                        </nav>
                    </div>
                )}
            </header>
            <section className="relative bg-gray-200 h-screen" id="home">
                <img
                    src={HeroImage}
                    loading="lazy"
                    alt="Window Blinds"
                    className="w-full h-full object-cover opacity-50"
                />

                <div className="absolute inset-0 flex flex-col justify-center md:items-start items-center px-3 md:px-14 pt-6">
                    <h1
                        className="text-[50px] text-center md:text-start md:text-[80px] font-bold text-gray-700"
                        data-aos="fade-right"
                    >
                        {contentText.heroSection.title1}
                    </h1>
                    <h2
                        className="text-4xl md:text-[60px] pt-4 md:pt-1 text-gray-700 mt-2 tracking-[10px] text-center md:text-start font-bold uppercase"
                        data-aos="fade-right"
                    >
                        {contentText.heroSection.title2}
                    </h2>
                    <p
                        className="text-[20px] pt-6 md:pt-6 md:text-[30px] text-center md:text-start font-semibold tracking-wider text-gray-800"
                        data-aos="fade-up"
                    >
                        {contentText.heroSection.tagline1}
                    </p>
                    <p
                        className="text-[20px] md:text-[25px] text-center md:text-start font-semibold tracking-wider text-gray-800 mt-3"
                        data-aos="fade-up"
                    >
                        {contentText.heroSection.tagline2}
                    </p>
                    <button
                        className="mt-8 py-3 px-8 bg-teal-800 text-white rounded-md text-lg uppercase hover:bg-teal-900"
                        data-aos="fade-left"
                    >
                        {contentText.heroSection.button}
                    </button>
                </div>
            </section>

            <Services text={text} language={language}/>
            <ProductCarousel text={text} language={language}/>
            <Aboutus text={text} language={language}/>
            <OurPreviousProject text={text} language={language}/>
            <TestimonialsCarousel  text={text} language={language}/>
            <ContactUS text={text} language={language}/>
          
        </>
    );
};

export default HeroPage;
